/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import UserService from "../../../service/user.service";

import {
  CCol,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import Multiselect from "multiselect-react-dropdown";
import DoubleRadiusInput from "../../FormComponents/DoubleRadiusInput";
import InputDateCustom from "../../FormComponents/InputDateCustom";
import {
  formatNumbersDot,
  toCapitalizeFirstLetter,
} from "../../../utils/TextUtils";
import { Spinner } from "reactstrap";

function getRandomNumberNLength(n = 1) {
  var numberLength = "";
  for (let i = 0; i < n; i++) {
    numberLength += `${Math.floor(Math.random() * (10 - 0) + 0)}`;
  }
  return numberLength;
}

function StepperPageComponent({
  needFirm,
  totalSteppers = 0,
  varsDictionary,
  listsDictionary,
  listDtaHook = [],
  page,
  steps = [],
  optionals_variables_list = [],
  VarsSelected,
  listSelected,
  needSupervise,
  priceDta = {
    subTotal: 0,
    total: 0,
    iva: 0,
    ivaAmount: 0,
    supervisePercentage: undefined,
    superviseAmount: 0,
  },
  onChangeVarsSelected = () => {},
  onChangeListSelected = () => {},
}) {
  const [listDta, setListDta] = listDtaHook;

  useEffect(() => {
    const requestOptions = async () => {
      const list = new Set(Object.keys(listsDictionary))//steps?.[page]?.lists;
      for (const element of list) {
        try {
          const { data } = await UserService.getListId(
            listsDictionary[element]?.value
          );
          setListDta((prevDta) => {
            return { ...prevDta, [element]: data.message };
          });
        } catch (error) {
          console.error(error);
        }
      }
    };
    if (listsDictionary) requestOptions();
  }, []);

  const handleChangeMultiSelect = (selected = [], nameList) => {
    const dataToSend = {};

    selected.forEach((sele) => {
      let nameId = sele.name;

      nameId = nameId.replace(/ /g, "_");
      nameId = nameId.substring(0, 6);
      nameId += getRandomNumberNLength(3);

      dataToSend[nameId] = sele.name;
    });
    onChangeListSelected(dataToSend, nameList);
  };

  return (
    <div className="stepper_page_component_container">
      <h3>
        {steps?.[page]?.title || ""}
        {needFirm && page === steps.length && "Firma electronica"}
        {!steps?.[page] && page === totalSteppers - 1 && "Confirmación"}
      </h3>
      <p>{steps?.[page]?.description || ""}</p>
      {
        steps?.[page]?.variables?.map((variable,i)=>{
          const name = variable.name
          if (variable.type ==='vars') 
            return <VarsComponent
              key={`var_document_${i}`}
              value={VarsSelected?.[name]}
              type={varsDictionary[name]}
              name={name}
              itsOptional={optionals_variables_list.includes(name)}
              onChangeVars={onChangeVarsSelected}/>
          return<ListComponent
              key={`list_document_${i}`}
              value={listSelected?.[name]}
              metaData={listsDictionary[name]}
              name = {name}
              list={listDta[name]}
              i={i}
              itsOptional = {optionals_variables_list.includes(name)}
              onChangeList = {handleChangeMultiSelect}
            />
        })
      }
      {steps?.[page]?.vars?.map((name, i) => {
        return <VarsComponent
        key={`var_document_${i}`}
        value={VarsSelected?.[name]}
        type={varsDictionary[name]}
        name={name}
        itsOptional={optionals_variables_list.includes(name)}
        onChangeList={onChangeVarsSelected}/>
      })}
      {steps?.[page]?.lists?.map((listName, i) => {
        return <ListComponent
        key={`list_document_${i}`}
          value={listSelected?.[listName]}
          metaData={listsDictionary[listName]}
          name = {listName}
          list={listDta[listName]}
          i={i}
          itsOptional = {optionals_variables_list.includes(listName)}
          onChangeList = {handleChangeMultiSelect}
        />
      }
      )}
      {needFirm && page === steps.length ? (
        <>
          <DoubleRadiusInput
            key={`vars_steps_conf1rm_f1rmed_Document`}
            title={"¿El documento se firma electrónicamente?"}
            onChange={onChangeVarsSelected}
            name={"conf1rm_f1rmed_Document"}
            value={VarsSelected["conf1rm_f1rmed_Document"]}
            trueValue="si"
            falseValue="no"
          />
          <p
            className="mt-5"
            style={{
              fontStyle: "italic",
              textAlign: "justify",
            }}
          >
            Colombia es uno de los países pioneros en reglamentación respecto al
            trámite de mensajes digitales (comercio electrónico). El marco legal
            colombiano comprende: Ley 527 de Agosto de 1999: Define y reglamenta
            el acceso y uso de los mensajes de datos, del comercio electrónico y
            de las firmas digitales, se establecen las entidades de
            certificación (parte de una PKI) y se dictan otras disposiciones,
            Decreto 1074 de Mayo de 2015. Decreto 2364 de Noviembre de 2012 . Se
            reglamenta el uso de la Firma Electrónica en el país. Teniendo en
            cuenta lo anterior, los documentos firmados electrónicamente son
            plenamente validos y eficaces en Colombia.
          </p>
        </>
      ) : (
        ""
      )}
      {!steps?.[page] && page === totalSteppers - 1 ? (
        <>
          <CRow>
            <CCol xs="auto" className="input_stepper">
              <label>Sub-total</label>
            </CCol>
            <CCol className="input_stepper">
              <p style={{ textAlign: "right" }} className="amount">
                ${formatNumbersDot(priceDta.subTotal, 2)}{" "}
              </p>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="auto" className="input_stepper">
              <label>+ IVA ({priceDta.iva}%)</label>
            </CCol>
            <CCol className="input_stepper">
              <p style={{ textAlign: "right" }} className="amount">
                ${formatNumbersDot(priceDta.ivaAmount, 2)}{" "}
              </p>
            </CCol>
          </CRow>
          {needSupervise ? (
            <CRow>
              <CCol xs="auto" className="input_stepper">
                <label>
                  Pago por supervisión{" "}
                  {priceDta.supervisePercentage &&
                    `${priceDta.supervisePercentage}%`}
                </label>
              </CCol>
              <CCol className="input_stepper">
                <p style={{ textAlign: "right" }} className="amount">
                  ${formatNumbersDot(priceDta.superviseAmount, 2)}{" "}
                </p>
              </CCol>
            </CRow>
          ) : (
            <></>
          )}
          <CRow>
            <CCol xs="auto" className="input_stepper total">
              <label>Total</label>
            </CCol>
            <CCol className="input_stepper">
              <p style={{ textAlign: "right" }} className="amount">
                ${formatNumbersDot(priceDta.total, 2)}{" "}
              </p>
            </CCol>
          </CRow>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

const propsAreEqual = (prev, post) => {
  return false;
};

export default React.memo(StepperPageComponent, propsAreEqual);

function VarsComponent({

  value,
  type,
  name,
  itsOptional,
  onChangeVars =()=>{}
}) {
  let type_data = null;
  let msg = name.replace(/['"]+/g, "").replaceAll("_", " ");

  if (type === "String") type_data = "text";
  else if (type === "Integer") type_data = "number";
  else if (type === "Float") type_data = "number";
  else if (type === "TextArea") type_data = "textarea";
  else if (type === "Date") {
    return (
      <div
        style={{
          maxWidth: "80%",
        }}
        className="input_stepper"
      >
        <label>{msg}</label>
        {!itsOptional ? (
          <span>*</span>
        ) : (
          ""
        )}
        <InputDateCustom
          name={name}
          onChange={onChangeVars}
          value={value}
        />
      </div>
    );
  } else if (type === "Checkbox") {
    return (
      <DoubleRadiusInput
        title={name}
        className="input_stepper"
        onChange={onChangeVars}
        name={name}
        value={value || ""}
        trueValue="si"
        falseValue="no"
      />
    );
  }

  let properties = {
    className: "input_vars",
    type: type_data,
    name: name,
    placeholder: "Escribe aquí",
    value: value || "",
    onChange: onChangeVars,
  };

  return (
    <div className="input_stepper">
      <label>{msg}</label>{" "}
      {!itsOptional ? <span>*</span> : ""}
      {type_data === "textarea" ? (
        <textarea
          rows={5}
          cols={5}
          {...properties}
          style={{ resize: "none" }}
        />
      ) : (
        <input {...properties} />
      )}
    </div>
  );}

function ListComponent({
  value,
  metaData,
  name,
  list,
  i,
  itsOptional,
  onChangeList =()=>{}
}) {
  
  if (!list)
    return (
      <React.Fragment>
        <div
        className="mb-2"
          style={{
            display: "flex",
            justifyContent: "center",
            color: '#93378a!important'
          }}
        >
          <Spinner animation="border" />
        </div>
      </React.Fragment>
    );

  return (
    <div className="input_stepper">
      <CFormLabel className="">
        {toCapitalizeFirstLetter(name.replace(/_/g, " "))}{" "}
        {!itsOptional ? (
          <span>*</span>
        ) : (
          ""
        )}
      </CFormLabel>
      <CInputGroup className="input-group-multiselect ">
        <CInputGroupText id={`basic-categori${i}`}></CInputGroupText>
        <Multiselect
          avoidHighlightFirstOption
          selectionLimit={!metaData.multi ? 1 : undefined}
          className={`w-100  ${
            !metaData.multi && value
              ? "hide_input_select"
              : ""
          }`}
          emptyRecordMsg="No hay categorías Disponibles"
          options={list.items
            .filter((it) => Boolean(it))
            .map((category) => {
              return {
                name: category.name,
                id: `${category.name}-${name}-${i}`,
              };
            })} // Options to display in the dropdown
          selectedValues={
            value
              ? Object.values(value).map((li) => {
                  return { name: li, id: `${li}-${name}-${i}` };
                })
              : []
          } // Preselected value to persist in dropdown
          onSelect={(e) => {
            if (!metaData.multi) {
              const optionListCont = document.querySelector(
                ".optionListContainer.displayBlock"
              );

              optionListCont.className =
                "optionListContainer displayNone";
              document.body.click();
              document.body.focus();
            }
            onChangeList(e, name);
          }} // Function will trigger on select event
          onRemove={(e) => onChangeList(e, name)} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />
      </CInputGroup>
    </div>
  );
}
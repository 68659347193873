import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createTemplates, updateTemplates } from "../actions/auth";
import UserService from "../service/user.service";

//import Template from "../components/Template";
import { Button } from "reactstrap";
import ViewTemplate from "../components/ViewTemplate";
import { searchElementByName } from "../utils/searchElementByName";
import CreateTemplate from "../components/CreateTemplate";
import useArray from "../utils/CustomHooks/useArray";
import useToast from "../utils/CustomHooks/useToast";
import ModalStepperform from "../components/modals/StepperFormDocument/ModalStepperform";
import tagService from "../service/tag.service";

const Templates = () => {
  const dispatch = useDispatch();
  const addToast = useToast();

  const [transfor, setTransform] = useState(false);
  const [totalVarsList, setTotalVarsList] = useState(0);
  const [invalidDta, setInvalid] = useState(false);
  const documentToEdit = useRef(null);

  const [clickButton, setClick] = useState("btn-shadow btn btn-dark");
  const [viewTemplate, setViewTemplate] = useState(true);
  const [state, setState] = useState([]);
  const [data, setData] = useState([]);
  const [dataCat, setDataCat] = useState([]);
  const [allCatg, setDataCategorie] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [alltags, setTags] = useState([]);
  const [varsSelect, setVarsSelect] = useState({});
  const [listSelect, setListSelect] = useState({});
  const [documentData, setDocument] = useState({});
  const [demoPaymentShow, setDemoPaymentShow] = useState(false);
  const previewDoc = useRef(null);
  const [refresh, setRefresh] = useState(false);

  const arrayHookStepper = useArray();

  const ChangeTemplate = () => setViewTemplate(!viewTemplate);
  const [info, setInfo] = useState({
    price: 0,
    //percentage_check: 0,
    supervise_price: undefined,
    need_firm: false,
    firm_price: undefined,
    supervise: false,
    percentage_check: false,
    optionals_variables_list: [],
  });

  const handleClickClose = () => {
    setTransform(false);
    //setClass({ butn: "btn-shadow btn btn-dark" });
  };

  useEffect(() => {
    let index = 0;
    let stateArray = [];
    UserService.getAllTemplate().then(
      (response) => {
        for (const key in response.data) {
          stateArray.push(response.data[key]);
          stateArray[index].id = key;
          index++;
        }
        setState(stateArray);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );

    UserService.getAllCategorias().then(
      (response) => {
        setDataCategorie(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.error(_content);
      }
    );
    
    tagService.getTags().then((tags)=>{
      setTags(tags.data)
    })
  }, [refresh]);

  const handleClickSearch = () => {
    setTransform(true);
    setClick("btn-shadow btn btn-dark btn-border");
  };
  const handleSubmit = async () => {
    const document = documentData.doc,
      vars = varsSelect,
      lists = listSelect,
      categoria = dataCat,
      stepperPages = arrayHookStepper.array,
      id_edit = documentToEdit.current?.id
    //validaciones
    
    const priceVerify =
      ((info.need_firm &&
        parseInt(`${info.firm_price || 0}`.replace(/,/g, "")) < 0) ||
        (info.supervise &&
          `${info.supervise_price || 0}`.replace(/,/g, "") < 0) ||
        parseFloat(`${info.price}`.replace(/,/g, "")) < 10000) &&
      !info.free_template;
    if (
      totalVarsList !== Object.keys(lists).length + Object.keys(vars).length ||
      stepperPages.find((page) => !page.title) ||
      priceVerify
    ) {
      let msg = "Faltan datos de las variables";
      let mdgError = { error: "E_DATOS" };
      if (stepperPages.find((page) => !page.title)) {
        msg = "Falta titulo de pagina";
        mdgError = { error: "page-name-error" };
      }
      if (parseFloat(`${info.price}`.replace(/,/g, "")) < 10000) {
        msg = "Precio inferior";
        mdgError = { error: "price-error" };
      }
      if (info.need_firm && !info.firm_price) {
        msg = "Falta precio de la firma";
        mdgError = { error: "firm_price-missing" };
      }
      if (info.supervise && !info.supervise_price) {
        msg = "Falta precio de la supervisión";
        mdgError = { error: "supervise_price-missing" };
      }
      setInvalid(true);
      addToast({
        title: "Error al crear documento",
        body: msg,
      });
      return mdgError;
    }
    let dict_catg = [];
    for (let index = 0; index < categoria.length; index++) {
      dict_catg.push(categoria[index].id);
    }
    let price = info.price,
      supervise_price = info.supervise_price,
      firm_price = info.firm_price;

    if (info.free_template) {
      price = 0;
      supervise_price = 0;
      firm_price = 0;
    }
    if (info.name && document) {
      try {
        
        if (id_edit) {
          await dispatch(
            updateTemplates(
              id_edit,
              info.name,
              document,
              `${price}`.replace(/,/g, ""),
              info.supervise,
              info.percentage_check,
              `${supervise_price}`.replace(/,/g, ""),
              dict_catg,
              vars,
              lists,
              info.IVA,
              info.need_firm,
              `${firm_price}`.replace(/,/g, ""),
              stepperPages.filter((step) => {
                return step?.variables?.length > 0
              }).map(step => ({variables: step.variables})),
              info.optionals_variables_list,
              selectedTags
            )
          );
        } else {
          await dispatch(
            createTemplates(
              info.name,
              document,
              `${price}`.replace(/,/g, ""),
              info.supervise,
              info.percentage_check,
              `${supervise_price}`.replace(/,/g, ""),
              dict_catg,
              vars,
              lists,
              info.IVA,
              info.need_firm,
              `${firm_price}`.replace(/,/g, ""),
              stepperPages.filter((step) => {
                return step?.variables?.length > 0
              }).map(step => ({variables: step.variables})),
              info.optionals_variables_list,
              selectedTags
            )
          );
        }
        addToast({
          title: "SE HA CREADO CORRECTAMENTE",
        });
        setRefresh(!refresh);
        setTimeout(() => ChangeTemplate(), 2000);
      } catch (error) {
        addToast({
          title: "Error al crear documento",
          body: error.message,
        });
      }
    } else {
      addToast({
        title: "Error al crear documento",
        body: "Aun faltan campos por completar",
      });
    }
    documentToEdit.current = null;
  };

  /*Buscando categoria */

  const handleSearchDocuemnt = (event, dataState) => {
    const value = searchElementByName(dataState, event.target.value);

    if (event.target.value === "") {
      setData([]);
    } else if (value.length !== 0) {
      setData(value);
    }
  };
  const handleEditDocument = (doc) => {
    
    documentToEdit.current = doc;
    setDocument({
      name: doc.name,
      doc: doc.document,
    });
    debugger
    setListSelect(doc.lists);
    setVarsSelect(doc.vars);
    //Object.keys(lists).length + Object.keys(vars).length
    setTotalVarsList(
      Object.values(doc.vars)?.length + Object.values(doc.lists)?.length
    );
    const reduce = alltags.reduce((prev,curr)=> {
      prev[curr.id] = curr
      return prev
    }, {})
    const selectedTags = doc.tags ? doc.tags.map(t=> reduce[t]) :[]
    setSelectedTags(selectedTags)
    const pageEmpty = {
      description: "",
      lists: [],
      title: "",
      vars: [],
      variables: []
    };
    const steps = doc.stepperPages.map((prev) => {
      const data = { ...pageEmpty, ...prev }
      if (!prev.variables) {
        data.variables = [
          ...data.vars.map((vars)=>({name: vars, type: 'vars'})),
          ...data.lists.map((list)=>({name: list, type: 'list'}))
        ]
      }
      return data
    });
    
    arrayHookStepper.set(steps);
    setInfo({
      supervise_price: doc.supervise_price,
      percentage_check: doc.percentage_check,
      price: doc.price,
      need_firm: doc.need_firm,
      firm_price: doc.firm_price,
      supervise: doc.supervise,
      name: doc.name,
      IVA: doc.iva,
      optionals_variables_list: doc.optionals_variables_list || [],
      free_template:
        (parseInt(doc.price || 0) +
          parseInt(doc.firm_price || 0) +
          parseInt(doc.supervise_price || 0)) === 0,
    });
    const categoriesFilter = allCatg
      .filter((cat) => {
        return doc.categorias?.includes(cat.id);
      })
      .map((cat) => ({ name: cat.name, id: cat.id }));
    setDataCat(categoriesFilter);
    setViewTemplate(false);
  };
  const handleTestDocument = (doc) => {
    previewDoc.current = doc;
    setDemoPaymentShow(true);
  };
  return (
    <div className="show_detail">
      <div className="table-responsive">
        <div className="header__field">
          <div className="image__list" onClick={() => ChangeTemplate()}>
            <i className="far fa-file" aria-hidden="true"></i>
          </div>
          <div className="text__information">
            {viewTemplate ? (
              <h4>Documentos creados</h4>
            ) : (
              <h4>Creación de Documentos</h4>
            )}
          </div>

          <div className="create__or__search active">
            <div className="input-search d-flex">
              {transfor ? (
                <>
                  <button
                    className={clickButton}
                    onClick={(e) => handleClickClose(e)}
                  >
                    <i className="fas fa-times"></i>
                  </button>

                  <input
                    type="text"
                    className="search-input"
                    placeholder="Buscar plantillas"
                    onChange={(e) => handleSearchDocuemnt(e, state)}
                  />
                </>
              ) : (
                <button
                  className={clickButton}
                  onClick={(e) => handleClickSearch()}
                >
                  <i className="fas fa-search"></i>
                </button>
              )}

              {viewTemplate ? (
                <Button
                  className="btn-search"
                  color="info"
                  onClick={() => {
                    documentToEdit.current = null;
                    ChangeTemplate();
                  }}
                >
                  <i className="fas fa-user-plus"></i>
                  <span className="ml-3">Nuevo documento</span>
                </Button>
              ) : (
                <Button
                  className="btn"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                  type="reset"
                >
                  <i className="fas fa-save"></i> Guardar
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {data.length > 0 || viewTemplate ? (
        <ViewTemplate
          state={data.length > 0 ? data : state}
          setRefresh={setRefresh}
          refresh={refresh}
          catg={dataCat}
          onEditDocument={handleEditDocument}
          onTestDocument={handleTestDocument}
        />
      ) : (
        <>
          <CreateTemplate
            documentEdit={documentToEdit.current}
            invalid={invalidDta}
            categories={allCatg}
            selectedCategories={dataCat}
            setSelectedCategories={setDataCat}
            documentDta={documentData}
            documentVars={varsSelect}
            documentList={listSelect}
            onDocumentChanged={(doc) => {
              setDocument({ ...doc });
            }}
            setDocumentList={setListSelect}
            setDocumentVars={setVarsSelect}
            arrayHookStepper={arrayHookStepper}
            dataFormHook={[info, setInfo]}
            totalVarsListHook={[totalVarsList, setTotalVarsList]}
            tags={alltags}
            selectedTagsHook={[selectedTags, setSelectedTags]}
          ></CreateTemplate>
        </>
      )}

      {demoPaymentShow && previewDoc.current && (
        <ModalStepperform
          test
          documentDta={previewDoc.current}
          showHook={[
            demoPaymentShow,
            (e) => {
              setDemoPaymentShow(e);
              previewDoc.current = null;
            },
          ]}
          dataHook={[data, setData]}
          inputs={Object.keys(previewDoc.current.lists).map((property) => {
            return {
              name: property,
              list: previewDoc.current.lists[property],
            };
          })}
          fieldsTop={Object.keys(previewDoc.current.vars).map((key_data) => {
            return {
              type_data: previewDoc.current.vars[key_data],
              msg: key_data,
            };
          })}
          rawDoc={previewDoc.current.document}
        />
      )}
    </div>
  );
};

export default Templates;
